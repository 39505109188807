<template>
  <div class="shi-xun-case">
    <h2>成功案例</h2>
    <p>来自各行各业的最佳案例</p>
    <div>
        <div class="case-tab-list-box">
            <div class="case-tab-list" :class="{'active':tagListIndex == 1}" @mousemove="tagListIndex = 1">
                <div class="case-tab-list-img"></div>
                <div class="case-tab-list-info">
                    <p>清易科技智能清洁场景</p>
                    <span>中软国际&深开鸿基于KaihongOS基座，利用GitIoT国产智联网开发应用平台，低成本地帮助清易科技的智能扫地机器人产品实现国产化改造，并快速实现室内家庭清洁场景的智联网场景开发，已实现全场景清洁模式智能规划、区域识别与划分、自定义清洁和远程操控等方面的功能</span>
                </div>
            </div>
            <div class="case-tab-list" :class="{'active':tagListIndex == 2}" @mousemove="tagListIndex = 2">
                <div class="case-tab-list-img"></div>
                <div class="case-tab-list-info">
                    <p>燃气安全监管平台</p>
                    <span>引入GitloT鸿易用场景创新应用端到端定制开发套件，快速搭建燃气安全装置报装应用，助力区经信委完成统计、安装、验收、补贴等工作；使用GitloT鸿易数数据智能采集分析套件，快速燃气安全视化报表。</span>
                </div>
            </div>
            <div class="case-tab-list" :class="{'active':tagListIndex == 3}" @mousemove="tagListIndex = 3">
                <div class="case-tab-list-img"></div>
                <div class="case-tab-list-info">
                    <p>蓝宙科技机器人绘画场景</p>
                    <span>GitIoT国产智联网开发应用平台在生产侧的引入和融合，低成本地帮助蓝宙科技的绘画机器人产品实现从生产、产品注册入库、电销跟踪、注册激活全过程跟踪管控，在各个节点完成GitIoT平台的赋能，完成客户的生产流程和产品的智能网融合。</span>
                </div>
            </div>
        </div>
        <span class="case-tab-left" v-if="tagListIndex > 1" :style="{left:tabLeft+'px'}" @mousemove="caseTag('left')"><a-icon type="left" /></span>
        <span class="case-tab-right" v-if="tagListIndex < 3" :style="{right:tabRight+'px'}" @mousemove="caseTag('right')"><a-icon type="right" /></span>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            tagListIndex:1,
            tabLeft:55,
            tabRight:142
        }
    },
    watch:{
        tagListIndex:function(val){
            if(val == 1){
                this.tabRight = 142
                return
            }
            this.tabRight = (val - 1) * 87 - 32
            this.tabLeft = (val - 1) * 87 - 32
        }
    },
    methods:{
        caseTag(type){
            let {tagListIndex} = this
            if(type == 'left'){
                this.tagListIndex = tagListIndex - 1
            }else{
                this.tagListIndex = tagListIndex + 1
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.shi-xun-case{
    background: #F8F9FA;
    padding: 64px 0;
    >h2{
        font-size: 32px;
        font-weight: 600;
        color: rgba(0,0,0,0.85);
        line-height: 45px;
        text-align: center;
    }
    >p{
        font-size: 18px;
        color: rgba(0,0,0,0.65);
        line-height: 28px;
        text-align: center;
        margin-bottom: 48px;
    }
    >div{
        width: 1264px;
        margin: 0 auto;
        position: relative;
        >span{
            position: absolute;
            width: 64px;
            height: 64px;
            background: #EA0B06;
            border-radius: 50%;
            line-height: 64px;
            text-align: center;
            color: #fff;
            top: 50%;
            margin-top: -32px;
            >i{
                font-size: 16px;
            }
        }
        .case-tab-list-box{
            flex-direction: row;
            width: 100%;
        }
        .case-tab-list-box,.case-tab-list{
            display: flex;
        }
        .case-tab-list{
            -moz-box-flex: 105;
            -moz-box-direction: reverse;
            background-color: #fff;
            -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
            -webkit-flex-grow: 105;
            flex-grow: 105;
            -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
            height: 363px;
            transition: all 1s;
            &:nth-child(1){
                .case-tab-list-img{
                    background-image: url(../../assets/img/al1.jpg);
                }
            }
            &:nth-child(2){
                .case-tab-list-img{
                    background-image: url(../../assets/img/al2.jpg);
                }
            }
            &:nth-child(3){
                .case-tab-list-img{
                    background-image: url(../../assets/img/al3.jpg);
                }
            }
            .case-tab-list-img{
                -moz-box-flex: 586;
                background-position: 100%;
                background-repeat: no-repeat;
                background-size: auto 100%;
                -webkit-flex-grow: 586;
                flex-grow: 586;
                height: 100%;
                position: relative
            }
            .case-tab-list-info{
                -moz-box-orient: vertical;
                -moz-box-direction: normal;
                display: -webkit-flex;
                display: -moz-box;
                display: flex;
                -webkit-flex-basis: 0px;
                flex-basis: 0px;
                -webkit-flex-direction: column;
                flex-direction: column;
                height: 100%;
                opacity: 0;
                >p{
                    margin-bottom: 24px;
                    width: 0;
                    font-weight: bold;
                    font-size: 18px;
                    color: rgba(0,0,0,0.85);
                    line-height: 25px;
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 2px;
                        height: 16px;
                        background: #EA0B06;
                        left: 0;
                        top: 4.5px;
                    }
                }
                >span{
                    display: block;
                    color: #000;
                    font-style: normal;
                    overflow: hidden;
                    text-align: justify;
                    width: 0;
                    font-size: 14px;
                    color: rgba(0,0,0,0.65);
                    line-height: 20px;
                }
            }
            &.active{
                -moz-box-flex: 876;
                -webkit-flex-grow: 876;
                flex-grow: 876;
                .case-tab-list-img{
                    background-size: 100% 100%;
                }
                .case-tab-list-info{
                    -webkit-flex-basis: 290px;
                    flex-basis: 290px;
                    opacity: 1;
                    padding: 36px;
                    transition: opacity .2s ease .2s;
                    >p{
                        width: 100%;
                        padding-left: 10px;
                    }
                    >span{
                        font-size: var(--font-size-big-text);
                        line-height: var(--font-size-big-text-lineheight);
                        margin-bottom: 62px;
                        width: 290px;
                    }
                }
            }
        }
    }
}
</style>