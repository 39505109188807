<template>
  <div class="shi-xun-introduce">
    <div>
        <h2>鸿联物联网实训平台</h2>
        <p>鸿联云实训平台是物联⽹设备接入及行业应用开发的PaaS训练平台。通过该平台可进行：</p>
        <div>
            <div>
                <span><img src="@/assets/img/introduceimg1.png"/></span>
                <p>虚拟/真实硬件设备接入物联网平台</p>
                <img src="@/assets/img/introduce1.png"/>
            </div>
            <div>
                <span><img src="@/assets/img/introduceimg2.png"/></span>
                <p>通过低代码工具快速构建行业应用</p>
                <img src="@/assets/img/introduce2.png"/>
            </div>
            <div>
                <span><img src="@/assets/img/introduceimg3.png"/></span>
                <p>通过可视化组建构建数据大屏</p>
                <img src="@/assets/img/introduce3.png"/>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.shi-xun-introduce{
    padding: 64px 0;
    background: #FAFBFD;
    >div{
        width: 1264px;
        margin: 0 auto;
        >h2{
            font-size: 32px;
            font-weight: 600;
            color: rgba(0,0,0,0.85);
            line-height: 45px;
            text-align: center;
            margin-bottom: 16px;
        }
        >p{
            font-size: 18px;
            color: rgba(0,0,0,0.65);
            line-height: 28px;
            text-align: center;
            margin-bottom: 48px;
        }
        >div{
            display: flex;
            justify-content: space-between;
            >div{
                width: 405px;
                height: 112px;
                background: #FFFFFF;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
                padding: 14px 16px;
                position: relative;
                display: flex;
                align-items: flex-start;
                >span{
                    width: 76px;
                    height: 76px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    >img{
                        max-width: 100%;
                    }
                }
                >p{
                    flex: 1;
                    margin-left: 16px;
                    padding: 16px 0;
                    position: relative;
                    font-size: 16px;
                    color: rgba(0,0,0,0.85);
                    line-height: 22px;
                    text-shadow: 0px 0px 5px rgba(0,0,0,0.05);
                    &::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 32px;
                        height: 2px;
                        background: #8B8F99;
                        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
                    }
                }
                >img{
                    position: absolute;
                    right: -4px;
                    bottom: -4px;
                    z-index: 2;
                }
            }
        }
    }
}
</style>